@import "./base.scss";

#banner {
  z-index: 2;
  text-shadow: $text_shadow;
  display: inline;
  justify-content: center;

  .banner {
    border: rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0.6em 0.6em 1em $composition0;
    border-style: $border-style;
  }

  h1 {
    font: bold 7vw/7vw $heading1;
    color: $composition9;
    text-align: center;
    overflow-wrap: break-word;
  }
}
