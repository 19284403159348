@import "./base.scss";

.majorframe {
  background: $composition8;
  background-image: radial-gradient($composition6, $composition7);
  border: 1px solid $composition9;
  @include standardbox;

  #optionsframe {
    .form-control,
    .form-select {
      color: $composition9;
      text-align: start;
      border-radius: 0;
    }
  }
  #infoblock {
    text-align: center;
    background: $composition2;
    background-image: radial-gradient($composition3, $composition2);
    border: 1px solid $composition8;
    @include standardbox;

    #header {
      color: $composition8;
      font: bold 1.5em/1rem $heading1;
    }

    #footer {
      color: $composition7;
      font: italic 0.75rem/0.5rem $heading2;
    }
  }

  .input-group-text {
    border-radius: 0;
  }

  .formhotkeys {
    text-align: start;
    background-color: $composition8;
  }

  .form-control,
  .form-select {
    color: $composition9;
    text-align: end;
    border-radius: 0;
  }

  .onlyprint {
    display: none;
  }
}

.hemesquare {
  border: 2px solid $composition8;
  text-align: center;

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    color: $composition9;
  }

  h5 {
    color: $composition8;
  }
}

@media print {
  @page {
    margin: 2cm;
  }

  .onlyprint,
  .onlyprint * {
    visibility: visible;
    display: block !important;
    border-radius: 0;
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
  }

  .noprint * {
    display: none;
  }

  .print,
  .print * {
    // all: unset;
    visibility: visible;
    display: block !important;
    border-radius: 0;

    // border: none !important;
    box-shadow: none !important;

    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      display: none;
    }
    input[type="number"] {
      -moz-appearance: textfield;
      /* Firefox */
    }

    main,
    article,
    section {
      display: block;
      width: 100%;
      page-break-inside: avoid;
      page-break-after: avoid;
      page-break-before: avoid;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 16px !important;
      font: bold;
    }

    // article {
    //   column-width: 17em;
    //   column-gap: 3em;
    // }
    table {
      break-inside: avoid;
    }
    * {
      background: none !important;
      background-image: none !important;
    }
  }
}
