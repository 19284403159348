@import "./base.scss";

/**========================================================================
 *                           Colors
 *========================================================================**/

$textPrimary: #fff;
$textSecondary: rgba(255, 255, 255, 0.7);
$textdisabled: rgba(255, 255, 255, 0.5);

$hightlight-color: rgb(210, 0, 0);

/**========================================================================
 *                           Override Defaults
 *========================================================================**/
// Override default variables before the import
$body-bg: $composition1;
$primary: red;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-shadows: true;
$enable-gradients: true;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

.onlyprint {
  display: none;
}

@media print {
  @page {
    margin: 0;
    all: unset;
  }

  body * {
    visibility: hidden;
    margin: 0;
    color: #000 !important;
    font-size: 12px !important;
    font: 13pt Georgia, "Times New Roman", Times, serif;
    line-height: 1;
  }
  #content,
  #page {
    width: 100%;
    margin: 0;
    padding: 0;
    float: none;
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
  }

  #task-summary .success td {
    background-color: #fff !important;

    /* add this line for better support in chrome */
    -webkit-print-color-adjust: exact;
  }

  * {
    background-image: none !important;
    background-color: #fff;
  }

  a {
    page-break-inside: avoid;
  }
  blockquote {
    page-break-inside: avoid;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
    page-break-inside: avoid;
  }
  img {
    page-break-inside: avoid;
    page-break-after: avoid;
  }
  table,
  pre {
    page-break-inside: avoid;
  }
  ul,
  ol,
  dl {
    page-break-before: avoid;
  }

  img,
  svg {
    display: none !important;
  }

  // img.print,
  // svg.print {
  //   display: block;
  //   max-width: 100%;
  // }
  img.dark {
    filter: invert(100%) hue-rotate(180deg) brightness(120%) contrast(150%);
  }

  header,
  footer,
  a,
  aside,
  nav,
  iframe,
  .form-floating,
  .menu,
  .hero,
  .adslot,
  #header-widgets,
  nav,
  aside.mashsb-container,
  .sidebar,
  .mashshare-top,
  .mashshare-bottom,
  .content-ads,
  .make-comment,
  .author-bio,
  .heading,
  .related-posts,
  #decomments-form-add-comment,
  #breadcrumbs,
  #footer,
  .post-byline,
  .meta-single,
  .site-title img,
  .post-tags,
  .readability,
  button,
  input,
  select,
  optgroup {
    display: none;
  }
}
