@import "./base.scss";
@import "./custom.scss";

/**========================================================================
 *                           Layout
 *========================================================================**/
// .container {
//   max-width: "100%";
// }

body {
  background: $composition1;
}

#App-logo {
  height: 40vmin;
  pointer-events: none;
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  #App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/**========================================================================
    *                           Layout
    *========================================================================**/

h1 {
  font: bold 4em/4em $heading1;
  text-align: center;
}

h2 {
  font: bold 2em/2em $heading2;
}

h3 {
  font: 25px/30px $heading1;
}

h4 {
  font: 20px/25px $heading2;
}

h5 {
  font: italic 16px/20px $heading1;
}

/**============================================
    *               Header
    *=============================================**/

header {
  z-index: 0;
  background-size: cover !important;
  #banner {
    z-index: 2;
    text-shadow: $text_shadow;
    display: inline;
    justify-content: center;

    .banner {
      border: rgba(0, 0, 0, 0.1);
      background: rgba(0, 0, 0, 0.4);
      box-shadow: 0.6em 0.6em 1em $composition0;
      border-style: $border-style;
    }

    h1 {
      font: bold 7vw/7vw $heading1;
      color: $composition9;
      text-align: center;
      overflow-wrap: break-word;
    }
  }
}

/**============================================
    *               Combined Sections
    *=============================================**/

#majorframe {
  background: $composition5;
  background-image: radial-gradient($composition7, $composition5);
  border: 1px solid $composition8;
  @include standardbox;

  h2 {
    color: $composition9;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  }

  h4 {
    color: $composition9;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  }

  h5 {
    color: $composition2;
  }

  p {
    color: $composition8;
  }
}

/**============================================
 *               Footer
 *=============================================**/

footer {
  z-index: 5;
  background: $composition1;
  background-image: linear-gradient($composition1, $composition0);
  color: $composition6;
  text-align: center;
  position: fixed;
  bottom: 0;

  .copyright {
    // display: flex;
    // justify-content: center;
    color: $composition5;
  }
}
